/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Container,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles";

import { db, firebase, admin } from "../../services/firebase";

import { TableContainer } from "../../styles/TableStyles";
import { FiSearch, FiTrash, FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import Modals from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";

function Confirmados(props) {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  let daysOfWeek = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];
  let todayDate = dd + "-" + mm + "-" + yyyy;

  const [newsAulaValue, setNewsAulaValue] = useState([]);

  const [state, setState] = useState({
    selectedIndex: "",
    allRequests: [],
    search: "",
    user: {},
    UserUid: "",
    aulaBooking_id: "",
    selectVagaAula: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  const getAllRequests = async () => {
    const snapshot = await db.collection("booking").get();

    let requests = [];
    let newArray = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      requests.push({ id, ...data });
      newArray = requests.filter(function (el) {
        return el && el.id.includes(todayDate);
      });
    });

    setState({ ...state, allRequests: newArray });
  };

  const searchFunction = async () => {
    let arr = [];

    if (!state.search) {
      getAllRequests();
    } else {
      let filtered = state.allRequests.filter(
        (obj) =>
          obj.agendado.find(
            (user) =>
              user.name.toLowerCase().indexOf(state.search.toLowerCase()) >=
                0 ||
              user.phone.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
          ) ||
          obj.aulas.name.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.data.toLowerCase().indexOf(state.search.toLowerCase()) >= 0 ||
          obj.aulas.end.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.aulas.begin.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allRequests: filtered });
    }
  };

  async function removeAgendado() {
    const docRef = firebase
      .firestore()
      .collection("booking")
      .doc(state.selectedIndex);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const arrayAgendados = doc.data().agendado;
          const arrayPendentes = doc.data().pendentes;

          let todoToken = [];

          for (const i in arrayPendentes) {
            todoToken.push(arrayPendentes[i].token);
          }

          const alunoParaRemover = arrayAgendados.filter((aluno) => {
            return state.UserUid === aluno.id ? aluno : null;
          });

          docRef
            .update({
              agendado: firebase.firestore.FieldValue.arrayRemove(
                alunoParaRemover[0]
              ),
            })
            .then(() => {
              toast.success(" Removido com sucesso.", {
                hideProgressBar: true,
              });
              getAllRequests();
              setOpenModal(false);
            })
            .catch((err) => console.log(err));
        } else {
          toast.error(" Não existe!", {
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        toast.error(" Ocorreu algum erro", {
          hideProgressBar: true,
        });
      });
  }

  async function changeNumberVaga() {
    try {
      const docRef = firebase
        .firestore()
        .collection("booking")
        .doc(state.aulaBooking_id);

      docRef.update({
        aulas: {
          name: newsAulaValue.name,
          aula_id: newsAulaValue.aula_id,
          begin: newsAulaValue.begin,
          end: newsAulaValue.end,
          day: newsAulaValue.day,
          description: newsAulaValue.description,
          instrutor: newsAulaValue.instrutor,
          url: newsAulaValue.url,
          vagas_number: state.selectVagaAula,
        },
      });
      toast.success("Número de Vaga actualizado com sucesso!");
      setOpenModalEdit(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <MainContent style={{ borderTop: 0, width: "100%" }}>
        <div className="headers" style={{ borderTop: 0 }}>
          <h2>Alunos Agendados</h2>
        </div>
        <div className="search_container">
          <SearchContainer>
            <FiSearch size={15} color="#ccc" />
            <input
              placeholder="Pesquisar aulas..."
              type="text"
              onChange={(e) => setState({ ...state, search: e.target.value })}
            />
          </SearchContainer>
        </div>
        <Modals
          open={openModal}
          setOpenModal2={setOpenModal}
          backgroundColor={"#fff"}
          width={30}
          height={35}
          headerText={"Eliminar"}
          onClose={() => setOpenModal(false)}
          showButons={true}
          onDelete={() => removeAgendado()}
          children={<h4>Tem certeza que deseja eliminar?</h4>}
        />

        <Modals
          open={openModalEdit}
          setOpenModalEdit={setOpenModalEdit}
          backgroundColor={"#fff"}
          width={35}
          height={35}
          headerText={"Alterar Número de Vaga"}
          onClose={() => setOpenModalEdit(false)}
          showButons={false}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <label>Número de Vaga</label>

            <Input
              value={state.selectVagaAula}
              onChange={(e) =>
                setState({ ...state, selectVagaAula: e.target.value })
              }
            />

            <Button onClick={changeNumberVaga}>Actualizar</Button>
          </div>
        </Modals>

        <div>
          <TableContainer>
            <table className="no-efectf">
              <thead>
                <tr className="table-header">
                  <th className="col" style={{ paddingLeft: 10 }}>
                    Nome Completo
                  </th>
                  <th className="col">Telefone</th>
                  <th className="col ">Aula</th>
                  <th className="col ">Dia</th>
                  <th className="col ">Hora de início</th>
                  <th className="col ">Hora de fim</th>
                  <th className="col ">Opções</th>
                </tr>
              </thead>
              <tbody>
                {state.allRequests.map((element, index) =>
                  element.agendado.map((el, i) => (
                    <>
                      <tr key={el.id}>
                        <td
                          className="col"
                          style={{ paddingLeft: 10 }}
                          data-label="Nome"
                        >
                          {el.name}
                        </td>
                        <td className="col" data-label="Telefone">
                          {el.phone}
                        </td>
                        <td className="col" data-label="Aula">
                          {element.aulas && element.aulas.name}
                        </td>
                        <td className="col" data-label="Dia">
                          {element.aulas && element.aulas.day}
                        </td>
                        <td className="col" data-label="Hora">
                          {element.aulas && element.aulas.begin}
                        </td>
                        <td className="col" data-label="Hora">
                          {element.aulas && element.aulas.end}
                        </td>
                        <td className="col">
                          <FiEdit
                            style={{ marginRight: 20, cursor: "pointer" }}
                            size={16}
                            className="icons"
                            onClick={() => {
                              console.log("Elemento", element);
                              console.log("Ele", el);
                              setState({
                                ...state,
                                aulaBooking_id: element.id,
                                selectVagaAula: element.aulas.vagas_number,
                              });

                              setNewsAulaValue(element.aulas);

                              setOpenModalEdit(true);
                            }}
                          />

                          <FiTrash
                            size={16}
                            className="icons"
                            onClick={() => {
                              setState({
                                ...state,
                                selectedIndex: element.id,
                                UserUid: el.id,
                              });
                              setOpenModal(true);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
          </TableContainer>
        </div>
      </MainContent>
    </Container>
  );
}

export default Confirmados;
